// import CXBus from "https://apps.usw2.pure.cloud/widgets/9.0/cxbus.min.js"
// CXBus.configure(
//                 {
//                     debug:false,
//                     pluginsPath:'https://apps.usw2.pure.cloud/widgets/9.0/plugins/'
//                 }
// );
// CXBus.loadPlugin('widgets-core');
var firstName;
var lastName;
var email;
var phone;

window._genesys = {
    "widgets": {
        "webchat": {
            "transport": {
                "type": "purecloud-v2-sockets",
                "dataURL": "https://api.usw2.pure.cloud",
                "deploymentKey": "2b9a064f-41d7-455c-bd17-81b78ad3ce18",
                "orgGuid": "873722bf-124d-4bd4-9cde-d7c5dca8b5ff",
                    "interactionData": {
                        "routing": {
                            "targetType": "QUEUE",
                            "targetAddress": "LLS TEST1",
                            "priority": 2
                        }
                    }
            },
        "userData": {
            "addressStreet": "",
            "addressCity": "",
            "addressPostalCode": "",
            "addressState": "WI",
            "phoneNumber": "",
            "customField1Label": "",
            "customField1": "",
            "customField2Label": "",
            "customField2": "",
            "customField3Label": "",
            "customField3": ""
        }
    },
        main: {
            themes: {
                blue: 'cx-theme-blue',
            },
            i18n: {
                en: {
                    webchat: {
                        ChatTitle: 'Life Line Screening Live Chat',
                        AriaWindowLabel: 'Life Line Screening Chat Window',
                        AriaMinimize: 'Life Line Screening Chat BOTMinimize',
                        AriaMaximize: 'Life Line Screening Chat BOT Maximize',
                        AriaClose: 'Life Line Screening Chat Close',
                    },
                },
            },
        },
    }
};

    function getAdvancedConfig() {
    return {
        "form": {
            "autoSubmit": false,
            "firstname": "",
            "lastname": "",
            "email": "",
            "phone": ""
        },
        "formJSON": {
                "wrapper": "<table></table>",
                "inputs": [
                            {
                                id: 'cx_webchat_form_firstname',
                                name: 'firstname',
                                maxlength: '100',
                                placeholder: 'Required',
                                "label": "First Name",
                                autocomplete: 'off',
                                "validateWhileTyping": true, // default is false
                                "validate": function (event, form, input, label, $, CXBus, Common) {

                                    let firstnameRegEx = /^[A-Za-z ]+$/;
                                    if (input && input.val() && firstnameRegEx.test(input.val()) && (input.val()).length >= 3) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            },
                            {
                                id: 'cx_webchat_form_lastname',
                                name: 'lastname',
                                maxlength: '100',
                                placeholder: 'Required',
                                "label": "Last Name",
                                "validateWhileTyping": true, // default is false
                                "validate": function (event, form, input, label, $, CXBus, Common) {
                                    let lastnameRegEx = /^[A-Za-z ]+$/;
                                    if (input && input.val() && lastnameRegEx.test(input.val()) && (input.val()).length >= 3) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            },
                            {
                                "id": "cx_webchat_form_email",
                                "name": "Email",
                                "maxlength": "100",
                                "placeholder": "Optional",
                                "label": "Email"
                            },
                            {
                                id: 'cx_webchat_form_phone',
                                name: 'phone',
                                maxlength: '10',
                                placeholder: 'Required',
                                "label": "Phone",
                                "validateWhileTyping": true, // default is false
                                "validate": function (event, form, input, label, $, CXBus, Common) {
                                    let phoneRegEx = /^(\+\d{1,3}[- ]?)?\d{10}$/;
                                    if (input && input.val() && phoneRegEx.test(input.val())) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            },
                            {
                                id: "cx_webchat_form_llquestion",
                                name: "Reason",
                                placeholder: "Required",
                                label: "Reason",
                                type: "select",
                                "validateWhileTyping": true, // default is false
                                "validate": function (event, form, input, label, $, CXBus, Common) {
                                if (input && input.val()) {
                                    if (input.val() === "Understanding your results") {
                                        window.open("https://www.lifelinescreening.com/screening-services/understanding-test-results?sourcecd=WORG001")
                                        // not sure if they want new window or redirect....
                                        //window.location.href = "https://www.lifelinescreening.com/screening-services/understanding-test-results?sourcecd=WORG001"
                                    }
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                                options: [
                            {
                                disabled: "disabled",
                                selected: "selected",
                                hidden: "hidden"
                            },

                            {
                                text: "Please select reason",
                                value: "",
                                "selected":true
                            },
                            {
                                text: "Understanding your results",
                                value: "Understanding your results"
                            },
                            {
                                text: "Missing Test Results",
                                value: "Missing Test Results"
                            },
                            {
                                text: "Results cannot print",
                                value: "Results cannot print"
                            },
                            {
                                text: "Incorrect results",
                                value: "Incorrect results"
                            },
                            {
                                text: "Requesting copies of films",
                                value: "Requesting copies of films"
                            },
                            {
                                text: "Results Pending",
                                value: "Results Pending"
                            },
                            {
                                text: "Copies of Ultrasound Images",
                                value: "Copies of Ultrasound Images"
                            }
                                ],
                                wrapper: "<tr><th>{label}</th><td>{input}</td></tr>"
                            }
                            ]
        }
    };
}
    window.getAdvancedConfig = getAdvancedConfig;
    window.customPlugin = CXBus.registerPlugin('Custom');

    exports = {customPlugin,getAdvancedConfig,_genesys}